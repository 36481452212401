import React, { useState, useRef, useEffect } from 'react';
import ProfileView from './Widgets/ProfileView';
import ProfileCarousel from './Widgets/ProfileCarousel';
import UnsplashModal from './../Modals/UnsplashModal';
import AddIcon from "../../assets/icons/add_icon.svg";
import ChipIcon from "../../assets/icons/chip_icon.svg";
import CurationIcon from "../../assets/icons/curation_icon.svg";
import useModal from './../hooks/ModalHook';
import { useDispatch, useSelector } from 'react-redux';
import { setGalleryData } from '../../redux/slices/gallerySlice';
import { setMyData } from '../../redux/slices/myDataSlice';
import ProfileIcon from "../../assets/icons/profile.svg";
import { postRequestAuthenticated, postRequestUnAuthenticated } from './../../services/rest';
import { fetchGallery,selectGalleryStatus } from './../../redux/slices/gallerySlice';
import ShareIcon from "../../assets/icons/shareIcon.svg";
import { setActiveTab } from '../../redux/slices/profileSlice';
import {  setProfileEngagement } from './../../redux/slices/profileEngagementSlice';
import { FaPencilAlt } from 'react-icons/fa';
import ProfileForm from './FormProfile/ProfileForm';
import ProfileSkeleton from './../skeleton/profileSkeleton';
import { domainUrl } from './../../utils/globals';
import EmptyProfileCard from './Widgets/EmptyProfileCard';
import { Outlet, useNavigate } from 'react-router-dom';
import Others from "../../assets/icons/Subtract.svg";



const Gallery = ({ onUnsplashClick  }) => {
  const [isUnsplashModalOpen, setIsUnsplashModalOpen] = useState(false);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);

  const dispatch = useDispatch();
  const galleryData = useSelector((state) => state.galleryData);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const myData = useSelector((state) => state.myData);
  const galleryStatus = useSelector(selectGalleryStatus);
  const [isLoading, setIsLoading] = useState(true);
  const [isDomainExist, setIsDomainExist] = useState(true);
  const { handleOpenModal} = useModal();


  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const openLoginModal = () => {
    handleOpenModal('modalLoginOpen');
  };



  useEffect(() => {
    const checkSubdomainExists = async (subdomain) => {
      try {
        const response = await postRequestUnAuthenticated(`/username/exist`,{username:subdomain});
        setIsLoading(false);
        if(response.success){
          dispatch(fetchGallery(galleryData.username));
        }
        else{
          setIsDomainExist(false);
        }
      } catch (error) {
        console.error('Error checking subdomain:', error);
        setIsLoading(false);
        alert("Please check internet connetion!");
      }
    };
    if(galleryData.username){
      checkSubdomainExists(galleryData.username);
    }
  }, [galleryData.username, dispatch]); 
  



  
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  const { items } = useSelector((state) => state.profileItems);


  const openUnsplashModal = () => setIsUnsplashModalOpen(true);
  const closeUnsplashModal = () => setIsUnsplashModalOpen(false);


  const handleSubscribe= async()=> {
    if(isLoggedIn){
      try {
        const response = await postRequestAuthenticated('/toggle/subscription',{receiverId: galleryData._id});
        if (response.success) {
          dispatch(setGalleryData({ subscribers: response.subscribers }));
          dispatch(setMyData({ subscriptions: response.subscriptions }));
        } else {
          console.error(response.message);
        }
      } catch (error) {
        console.error('Error fetching profile', error);
      } finally {
        dispatch(setProfileEngagement(galleryData._id));
      }
    }
    else{
      openLoginModal();
    }

   
  }

  const openBottomSheet = () => setIsBottomSheetOpen(true);
  const closeBottomSheet = () => setIsBottomSheetOpen(false);

  const handleCurationOpenModal=()=>{
    if(isLoggedIn){
      setIsDropdownOpen(false);
      handleOpenModal('modalCurationOpen');
    }
    else{
      openLoginModal();
    }
   
  }
  const handleChipOpen = () => {
    if(isLoggedIn){
      setIsDropdownOpen(false);
      handleOpenModal('modalChipOpen');
    }
    else{
      openLoginModal();
    }
   
  };

  const handleEditCards=()=>{
    if(isLoggedIn){
      dispatch(setActiveTab("displayCards"));
      setIsBottomSheetOpen(true);
    }
    else{
      openLoginModal();
    }
  }

  const openShareModal = (link) => {
    handleOpenModal('modalShareProfileOpen', link);
    dispatch(setProfileEngagement(galleryData._id));
  };

  if(isLoading){
    return <ProfileSkeleton/>
  }

  if(!isDomainExist){
    navigate(`/404`);
    return ;
  }
 
  const hasImages = galleryData.imageCards.length!==0;
  const isOwner = galleryData?.username === myData?.username;
  const isSubscribed = galleryData.subscribers?.includes(myData._id);


  return (
    <div className="w-full pr-5 xs:pr-8 sm:pr-16 h-full" onClick={handleClickOutside}>
      {galleryStatus==="loading"?(
        <ProfileSkeleton/>
      ):(
        <>
      {isOwner && <button
        className={`xs:bg-dark p-2 flex flex-row absolute right-0 xs:right-16 ${isOwner && !hasImages?"xl:right-[14%]":"xl:right-[10%]"} items-center -mt-8 rounded-full`}
        onClick={openBottomSheet}>
        <FaPencilAlt className="w-3 h-3 text-textFieldColor mb-0.5" />
        <p className="text-textFieldColor ml-2 text-xs font-normal font-inter">Edit Profile</p>
      </button>}
      <div className={`mt-7 py-1 ${isOwner && !hasImages?"xl:w-4/5 xl:mx-auto":"xl:w-[90%] xl:mx-auto"}`}>
       <div className={`${isOwner || hasImages ? 'md:bg-profileBackground' : ''} bg-primaryBackground rounded-lg md:px-7 px-0  py-4 sm:py-8 w-full`}>
           <div className={`flex flex-col md:flex-row ${isOwner || hasImages ? 'items-center md:justify-between justify-center' : 'items-center justify-center'} `}>
            <div className={`md:w-2/5 w-full flex flex-col ${isOwner || hasImages ? "md:justify-start md:items-start justify-center items-center":"justify-center items-center"}  mb-4`}>
            {galleryData.logo ? <img
                src={galleryData.logo}
                alt="Profile"
                className="rounded-full w-28 h-28 border border-white object-cover"
                style={{ borderWidth: "3px" }}
              />:<img
              src={ProfileIcon}
              alt="Profile"
              className="rounded-full w-28 h-28 border bg-buttonBackground border-white object-cover"
              style={{ borderWidth: "2px" }}
            />}
               <div className={`mt-1 md:ml-1  ${isOwner || hasImages ? 'profile-text' : 'text-center'}`}>
                <h1 className="text-xl text-white font-normal font-familjen-grotesk">{galleryData.name}</h1>
                <p className="mt-1 text-xs  font-normal text-viewAll font-inter">{galleryData.username}.chips.social</p>
                <p className="mt-1 text-xs  font-normal text-viewAll font-inter">{galleryData.subscribers.length} Subscribers</p>
                <p className="mt-2 text-sm font-light text-textColor">
                 {galleryData.description}
                </p>
                {(galleryData.location || galleryData.contact) &&<p className="mt-2 text-xs text-viewAll">{galleryData.location}{galleryData.contact && galleryData.location?" | ":""}{galleryData.contact}</p>}
                <div className={`flex space-x-4 ${isOwner || hasImages ? 'md:justify-start justify-center' : 'justify-center'}`}>
                  {galleryData.customText &&  galleryData.customUrl && <a href={galleryData.customUrl} 
                  target="_blank"
                  rel="noopener noreferrer"
                  className="px-5 mt-4 py-2 bg-primary text-buttonText text-sm rounded-lg">{galleryData.customText}</a>}
                {!isOwner &&  <button className={`px-4 mt-4 py-2 ${isSubscribed?"bg-dark":"bg-buttonBackground"} text-primary text-sm rounded-lg`} onClick={handleSubscribe}>{isSubscribed?"Subscribed":"Subscribe" }</button>}
                </div>
                <div className={`flex ${isOwner || hasImages?"md:justify-start justify-center":"justify-center"}`}>
                <div className="w-64 my-3  border border-borderColor " style={{ height: '0.1px' }}></div>
                </div>
                <div className={`flex space-x-4 ${isOwner || hasImages?'justify-center md:justify-start':'justify-center'}`}>
                {/* {isOwner?( */}
                      <div 
                      className="bg-primary rounded-full cursor-pointer"
                      onClick={()=>openShareModal(`https://`+galleryData.username+`.${domainUrl}`)}
                      >
                      <img src={ShareIcon} alt="save" />
                    </div>
                    {/* ):(<a 
                      href={`https://${galleryData.username}.${domainUrl}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-primary rounded-full cursor-pointer ">
                      <img src={ShareIcon} alt="save" />
                    </a>)} */}
                    {galleryData.links.map((link, index) => (
                        link.value && (
                           (
                              <a 
                                href={link.url + link.value} 
                                className="text-white cursor-pointer"
                                key={index}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img src={link.image} alt={link.value} className="w-8 h-8"/>
                              </a>
                           )
                        )
                      ))}
                       {galleryData.otherLink &&  <div className="text-white cursor-pointer"  onClick={() => openShareModal(galleryData.otherLink)}>
                              <img src={Others} alt="other-link" className="w-8 h-8"/>
                      </div>}
                </div>
              </div>
            </div>
            {hasImages ? (<div className={`flex md:ml-4 md:mt-0 mt-4 md:mb-0 justify-center ${(!galleryData.description || galleryData.description.length<=40)?"md:w-3/5 xl:w-2/5":"md:w-3/5 xl:w-1/2"} h-full w-full md:justify-end mb-2`}>
              <ProfileCarousel images={galleryData.imageCards} />
            </div>
             ) :
             isOwner ? (   <div className={`flex md:ml-4 md:mt-0 mt-4 md:mb-0 justify-center ${(!galleryData.description || galleryData.description.length<=40)?"md:w-3/5 xl:w-3/5":"md:w-3/5 lg:w-1/2"} h-full w-full md:justify-end mb-2`}>
             <EmptyProfileCard handleClick={handleEditCards} />
             </div>
              ) : null}
          </div>
        </div>
        <div className={`flex justify-between items-center ${hasImages ? 'mt-2 xs:mt-6' : 'mt-1'} -ml-2`}>
        {isOwner && items.length>0 && <div className="flex items-center cursor-pointer" onClick={toggleDropdown}>
            <div className="rounded-md flex justify-center items-center">
              <img src={AddIcon} alt="Add" className="w-13 h-12 mt-3" />
            </div>
            <p className="text-primary font-normal text-xs -ml-1">Create new</p>
          </div>}
          {isDropdownOpen && (
            <div
              ref={dropdownRef}
              className="absolute mt-28 ml-3 w-32 rounded-md shadow-lg border border-dividerLine bg-chipBackground ring-1 ring-black ring-opacity-5 z-50"
            >
              <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <div className="flex flex-row px-3 items-center" onClick={handleChipOpen}>
                        <img src={ChipIcon} alt="edit" className="w-4 h-4"/>
                        <p
                  className="block px-2 py-2 text-sm text-textFieldColor cursor-pointer"
                  role="menuitem"
                  
                >
                  Chip
                </p>
                  </div>
                  <div className="flex flex-row px-3 items-center" onClick={handleCurationOpenModal}>
                        <img src={CurationIcon} alt="edit" className="w-4 h-4"/>
                        <p
                        className="block px-2 py-2 text-sm  text-textFieldColor cursor-pointer"
                        role="menuitem"
                      >
                        Curation
                      </p>
                  </div>
              </div>
            </div>
          )} 
        </div>
        <ProfileView gallery ={true} owner={isOwner} />
        </div>
        <ProfileForm
        isOpen={isBottomSheetOpen}
        onClose={closeBottomSheet}
        onUnsplashClick={openUnsplashModal}
        gallery={true}
      />
      <UnsplashModal
        open={isUnsplashModalOpen}
        onClose={closeUnsplashModal}
      />
      </>
    )}
     <Outlet />
    </div>
  );
};

export default Gallery;
