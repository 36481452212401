import React from 'react';
import Logo from '../../assets/images/chips_logo.svg';
import useModal from './../hooks/ModalHook';


const Footer = () => {


  const { handleOpenModal} = useModal();


  const handleTermsOpen = () => {
    handleOpenModal('modalTermsOpen');
  };
  const handlePrivacyOpen= () => {
    handleOpenModal('modalPrivacyOpen');
  };
  return (
    <footer className="bg-dark w-full text-white h-40  text-center mt-10 flex">
      <div className="flex flex-col px-4 sm:px-6 lg:px-12 md:px-12  w-full justify-center">
        <div className="flex flex-row justify-between items-start">
            <div className="flex flex-row items-center">
            <img src={Logo} alt="Logo"
            className="w-7 h-7"
            >
            </img>
            <p className="font-familjen-grotesk ml-2 font-bold text-xl text-white" style={{letterSpacing:"1.5px"}}>Chips</p>
            </div>
            <div className="flex flex-col items-end">
                <p className="font-inter ml-2 font-light text-xs text-textFieldColor">Follow us @chips.social</p>
                <div className="flex flex-row space-x-5 mt-3">
                  <a href="https://www.instagram.com/chips.social/" target="_blank" rel="noopener noreferrer" className="text-primary text-xs font-normal font-inter leading-tight">Instagram</a>
                  {/* <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-primary text-xs font-normal font-inter leading-tight">Twitter</a> */}
                  <a href="https://facebook.com/chips.social/" target="_blank" rel="noopener noreferrer" className="text-primary text-xs font-normal font-inter leading-tight">Facebook</a>
                  <a href="https://www.linkedin.com/company/chips2connect/?viewAsMember=true" target="_blank" rel="noopener noreferrer" className="text-primary text-xs font-normal font-inter leading-tight">LinkedIn</a>
                </div>
            </div>
        </div>
        <div className="flex flex-row justify-between mt-8 items-center">
            <div className="flex flex-row">
          <p className="text-primary  text-[10px]  md:text-xs font-normal font-inter leading-tight cursor-pointer" onClick={handleTermsOpen}>Terms of Service</p>
          <p className="text-primary text-[10px]  md:text-xs font-normal ml-4 font-inter leading-tight cursor-pointer" onClick={handlePrivacyOpen}>Privacy Policy</p>
            </div>
            <p className="text-primary text-[10px]  md:text-xs font-normal font-inter leading-tight">&copy; 2023 Chips2Connect Private Limited. All rights reserved.</p>
        </div>
        
      </div>
    </footer>
  );
};

export default Footer;
