import React, { useState,useEffect,useRef, useCallback } from 'react';
import ShowChips from './ShowChips';
import { useDispatch, useSelector } from 'react-redux';
import { clearCurationItems,fetchChipsofCuration,selectCuration, clearCuration,fetchMoreCurations } from './../../../redux/slices/homeSlice';
import { useNavigate } from 'react-router-dom';
import LazyLoad from 'react-lazy-load';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight,faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import debounce from 'lodash.debounce';
 
const CurationList = ({curations,category}) => {

  const dispatch = useDispatch();
  const [id,setId] = useState(null);
  
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const selectedCuration = useSelector((state) => state.homeItems.selectedCuration);
  const curationsLoading = useSelector((state) => state.homeItems.curationsLoading);
  const listRef = useRef(null);
  const [categoryIndex, setCategoryIndex] = useState(curations?.length);

  const fetchFurtherCurations = useCallback(() => {
    if (!curationsLoading) {
      console.log('Fetching more curations');
      dispatch(fetchMoreCurations({ category, fetchedCurationsIds: curations.map((c) => c._id) }))
        .unwrap()
        .then((newCurations) => {
          if (newCurations && newCurations.length) {
            setCategoryIndex((prevIndex) => prevIndex + newCurations.length);
          }
          if (listRef.current) {
            listRef.current.scrollBy({
              left: 250,
              behavior: 'smooth',
            });
          }
        });
    }
  }, [curations, category, curationsLoading, dispatch]);

  const fetchFurtherAutoCurations = useCallback(() => {
    if (!curationsLoading) {
      console.log('Fetching more curations');
      dispatch(fetchMoreCurations({ category, fetchedCurationsIds: curations.map((c) => c._id) }))
        .unwrap()
        .then((newCurations) => {
          if (newCurations && newCurations.length) {
            setCategoryIndex((prevIndex) => prevIndex + newCurations.length);
          }
          // if (listRef.current) {
          //   listRef.current.scrollBy({
          //     left: 250,
          //     behavior: 'smooth',
          //   });
          // }
        });
    }
  }, [curations, category, curationsLoading, dispatch]);

  const handleCurationClick = (curation) => {
    dispatch(clearCurationItems());

    if (window.innerWidth < 470) {
      navigate(`/curation/${curation._id}`);
    } 
    else{
      if(selectedCuration===curation){
        setId(null);
        dispatch(clearCuration());
      }
      else{
        dispatch(selectCuration(curation));
        setId(curation._id);
        dispatch(clearCurationItems());
        dispatch(fetchChipsofCuration(curation._id))
         
      }
    }
   
  };

  const handleHide = () => {
    dispatch(clearCuration());
  };

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 470);

  // const handleScroll = useCallback(() => {
  //   if (!curationsLoading && listRef.current && listRef.current.scrollLeft + listRef.current.clientWidth >= listRef.current.scrollWidth - 10) {
  //     fetchFurtherCurations();
  //   }
  // }, [curationsLoading, fetchFurtherCurations]);

  const debouncedScroll = useCallback(
    debounce(() => {
      if (!curationsLoading && listRef.current && listRef.current.scrollLeft + listRef.current.clientWidth >= listRef.current.scrollWidth - 10) {
        fetchFurtherAutoCurations();
      }
    }, 300), // Debounce interval of 300ms to avoid excessive calls
    [curationsLoading, fetchFurtherCurations]
  );

  // Detect scroll and fetch more curations if needed
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 470);
    };
    window.addEventListener('resize', handleResize);

    const ref = listRef.current;
    if (ref) {
      ref.addEventListener('scroll', debouncedScroll);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      if (ref) {
        ref.removeEventListener('scroll', debouncedScroll);
      }
    };
  }, [debouncedScroll]);


  const handleScrollRight = (e) => {
    e.stopPropagation(); // Prevent click event propagation to the parent
    if (listRef.current) {
      listRef.current.scrollBy({
        left: 250, 
        behavior: 'smooth',
      });
      if (listRef.current.scrollLeft + listRef.current.clientWidth >= listRef.current.scrollWidth-10) {
        fetchFurtherCurations(); 
      }
    }
  };

  const handleScrollLeft = (e) => {
    e.stopPropagation();
    if (listRef.current) {
      listRef.current.scrollBy({
        left: -250, 
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="w-full mt-1 -ml-2 relative"
      onMouseEnter={() => setIsHovered(true)}  
      onMouseLeave={() => setIsHovered(false)}
    >
      <div ref={listRef} className="flex flex-row xs:space-x-5 space-x-1 overflow-x-auto no-scrollbar ">
        {curations.map((curation, index) => (
          <div
            key={index}
            className={`flex flex-col items-start flex-shrink-0 cursor-pointer ${selectedCuration === curation ? 'bg-dark border border-primary' : ''} p-2 rounded-lg`}
            onClick={() => handleCurationClick(curation)}
          >
            <LazyLoad 
              height={isSmallScreen ? 114 : 130} 
              offset={200}
              once
              debounce={false}
              placeholder={<img src="https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png" alt="placeholder" />}
            >
              <img className="xs:h-32 xs:w-48 h-28 w-44 rounded-lg object-cover" src={curation.image} alt={curation.name}
                onError={(e) => { e.target.src = "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"; }}
              />
            </LazyLoad>
            <p
              className="text-white text-sm mt-1 w-48 font-inter font-normal text-left overflow-hidden"
              style={{
                display: '-webkit-box',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                lineHeight: '1.5rem',
              }}
            >
              {curation.name}
            </p>
            <p className="text-viewAll text-xs font-inter font-normal text-start">{curation.user.name}</p>
          </div>
        ))}
        
        {/* Loader for showing when more curations are being fetched */}
        {curationsLoading && (
          <div className="flex flex-col items-center justify-center  text-white">
            <div className="loader"></div>
            <div className="text-white text-sm mt-1">Loading...</div>
        </div>
        )}
      </div>

      {/* Arrow buttons for scrolling */}
      {isHovered && (
        <>
          <button
            className={`absolute right-1 px-2.5 top-[40%] transform -translate-y-1/2 bg-profileBackground text-white py-2 rounded-full sm:flex hidden`}
            onClick={handleScrollRight}
          >
            <FontAwesomeIcon icon={faArrowRight} size="lg" />
          </button>

          <button
            className={`absolute left-3 px-2.5 top-[40%] transform -translate-y-1/2 bg-profileBackground text-white py-2 rounded-full sm:flex hidden`}
            onClick={handleScrollLeft}
          >
            <FontAwesomeIcon icon={faArrowLeft} size="lg" />
          </button>
        </>
      )}

      {/* Show chips for selected curation */}
      {selectedCuration._id === id && (
        <ShowChips onHide={handleHide} category={category} curation={selectedCuration} />
      )}
    </div>
  );
};


export default CurationList;