import React, { useRef, useEffect, useState } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import UpArrow from '../../../assets/icons/up-arrow.svg';
import CrossArrow from '../../../assets/icons/arrow_cross.svg';
import Chips from './../../chips/Chips';
import { useSelector, useDispatch } from 'react-redux';
import ProfileChips from './../../chips/ProfileChips';
import { setChipField} from '../../../redux/slices/chipSlice';
import useModal from './../../hooks/ModalHook';
import { useNavigate } from 'react-router-dom';
import ProfileItemsSkeleton from './../../skeleton/profileItemsSkeleton';


const ShowChips = ({  onHide, category, curation}) => {
  const breakpointColumnsObj = {
    default: 4,
    1100: 4,
    900:3,
    700: 2,
    580: 2,
    300:1
  };
  const containerRef = useRef(null);
  const [scrollTop, setScrollTop] = useState(50);
  const myData = useSelector((state) => state.myData);
  const dispatch = useDispatch();
  const { handleOpenModal} = useModal();

  const {chips, chipsstatus} = useSelector((state) => state.homeItems);
  const navigate = useNavigate();



  useEffect(() => {
    setScrollTop(50);
    const handleScroll = () => {
      const container = containerRef.current;
      if (container) {
        const containerTop = container.getBoundingClientRect().top;
        const containerHeight = container.offsetHeight;
        const pageScrollY = window.scrollY;
        const newTopPosition = Math.min(Math.max((pageScrollY - containerTop) * 0.5 + 50, 50), containerHeight - 50); 
        setScrollTop(newTopPosition);
      }
      
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  
  const handleHideClick = () => {
    onHide();
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleCategory=(category)=>{
    const encodedCategory = encodeURIComponent(category);
    navigate(`/categories/${encodedCategory}`);
  }

  const handleEmptyCuration=()=>{
    if(myData._id===curation.user._id){
      dispatch(setChipField({field:"curation",value:curation._id}));
      handleOpenModal('modalChipOpen');
    }
    else{
      handleCategory(category);
    }
  }

  return (
    <div className="relative">
      <div className="flex flex-col" ref={containerRef}>
        { chipsstatus==="loading"?<div className="mt-5"><ProfileItemsSkeleton/>
          </div>: chips.length>0?<div className="pl-2 mt-6 w-[97%]">
          <ResponsiveMasonry columnsCountBreakPoints={breakpointColumnsObj}>
            <Masonry className="my-masonry-grid" gutter="13px" columnClassName="my-masonry-grid_column">
              {chips.map((item) => (
              myData._id!==item.user._id? <Chips key={item._id} item={item} />:<ProfileChips key={item._id} item={item}/>
              ))
            }
            </Masonry>
          </ResponsiveMasonry>
            </div>: <div className="flex items-center justify-center mx-auto mt-10 mb-12">
              <div className="container rounded-md bg-dark pl-4 pr-4 pt-3 pb-3 flex flex-col min-w-fit  " >
                <h3 className=" text-textColor  text-sm">(. ❛ ᴗ ❛.) Seems like this curation is empty</h3>
                <div className="mt-2 rounded-md bg-chipBackground pl-5 pt-4 pb-4 cursor-pointer" >
                  <p className=" text-textColor text-xs font-light"
                  onClick={handleEmptyCuration}
                  >{myData._id===curation.user._id || curation.visibility==="anyone"?"Add a Chip ->": `Explore more in ${category} ->`}</p>
                </div>
               {curation.visibility==="anyone" && myData._id!==curation.user._id &&  <p className=" text-textColor text-xs mt-3 pl-5 font-light"
                  onClick={handleCategory}
                  >Explore more in {category} -></p>}
              </div>
            </div>}
            <div className="relative mt-6">
              <div className="horizontal-line bg-borderColor h-px mr-5" />
              <div className="absolute -top-3 w-full flex flex-col items-center cursor-pointer ">
                <img
                  src={UpArrow}
                  alt="up-arrow"
                  onClick={handleHideClick}
                  className="text-borderColor -mt-0.5 font-extralight cursor-pointer"
                />
                <p className="text-lightText text-xs -mt-0.5 ml-1">Hide</p>
              </div>
            </div>
        </div>
        <div className="hidden xs:flex absolute top-3 lg:right-2 sm:right-0 -right-1 h-full-minus-12 w-5 flex-col items-center justify-center z-10">
          <div className="w-px bg-borderColor h-full"></div>
            <img
              src={CrossArrow}
              alt="cross-icon"
              className="absolute  transform -translate-y-1/2 w-8 h-8 cursor-pointer z-20"
              onClick={handleHideClick} 
              style={{ top: `${scrollTop}px` }} 
            />
        </div>
        <div
        className="xs:hidden fixed bottom-4 right-4 z-20"
      >
        <button
          className="bg-dark text-white rounded-full p-1.5 shadow-lg flex items-center justify-center"
          onClick={handleHideClick}
        >
          <img src={UpArrow} alt="up-arrow" className="w-8 h-8" />
        </button>
      </div>
    </div>
  );
};

export default ShowChips;
