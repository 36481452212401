import "./App.css";
import "@radix-ui/themes/styles.css";
import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Internetconnection from "./components/InternetConnection/Internetconnection";
import Navbar from "./components/Navbar/Navbar";
import Sidebar from "./components/Sidebar/Sidebar";
import Footer from "./components/Footer/Footer";
import LoginModal from "./components/Modals/LoginModal";
import CurationModal from "./components/Modals/CurationModal";
import ResetPassword from "./utils/ResetPassword";
import Saved from "./components/Saved/Saved";
import Home from "./components/Home/Home";
import CurationView from "./components/View/CurationView";
import Profile from "./components/Profile/Profile";
import SubscriptionsView from "./components/Home/my_subscriptions/SubscriptionsView";
import Page404 from "./components/Page404/Page404";
import SaveChip from "./components/Modals/SaveChip";
import ChipsModal from "./components/Modals/ChipsModal";
import OnboardingModal from "./components/Modals/Onboarding/Onboarding";
import UnsplashModal from "./components/Modals/UnsplashModal";
import ProfileChipsView from "./components/View/ProfileChipsView";
import useInitializeApp from "./services/initializeApp";
import PrivacyPolicy from "./components/Modals/policies/PrivacyPolicy";
import TermsofService from "./components/Modals/policies/TermsofService";
import DeleteChipModal from "./components/Modals/deletions/DeleteChipModal";
import DeleteCurationModal from "./components/Modals/deletions/DeleteCurationModal";
import { extractSubdomain } from "./utils/extractDomain";
import { setGalleryData } from "./redux/slices/gallerySlice";
import Gallery from "./components/Profile/Gallery";
import EditChipModal from "./components/Modals/EditChipModal";
import Logo from "./assets/icons/chips.png";
import ChipState from "./components/chips/ChipState";
import ScrollToTop from "./services/scrollTop";
import Unsplash from "./components/Modals/CurationUnplash";
import ShareModal from "./components/Modals/share/ShareModal";
import ChipShareModal from "./components/Modals/share/ChipShareModal";
import LogoutModal from "./components/Modals/LogoutModal";
import { Helmet } from "react-helmet";
import CurationsCategory from "./components/View/CurationsCategory";
import QueryPage from "./components/query";
import { checkTokenExpiry } from "./services/cookies";
import TokenExpiryModal from "./components/Modals/TokenExpiryModal";
import ProfileShareModal from "./components/Modals/share/profileShareModal";
import AdminHome from "./components/Admin/AdminHome";
import ManageSubscriptions from "./components/Home/manage_subscriptions/ManageSubscriptions";
import UnsubscriptionModal from "./components/Modals/Unsubsribe/UnsubscriptionModal";
import Domain404 from "./components/Page404/Domain404";
import useModal from "./components/hooks/ModalHook";
import DocumentModal from "./components/Modals/widgets/DocumentModal";
import CommentChipModal from "./components/Modals/comments/CommentChipModal";
import CreateCategoryModal from "./components/Modals/CreateCategoryModal";
import DeleteCategoryModal from "./components/Modals/deletions/DeleteCategoryModal";
import PushtoCategoryModal from "./components/Modals/PushItems/pushtoCategoryModal";
import PushtoCurationModal from "./components/Modals/PushItems/pushtoCurationModal";
import CategoryReorderModal from "./components/Modals/category/CategoryReorderModal";

const clientId =
  "391369792833-72medeq5g0o5sklosb58k7c98ps72foj.apps.googleusercontent.com";

const App = () => {
  useInitializeApp();
  const dispatch = useDispatch();
  const [hasSubdomain, setHasSubdomain] = useState(false);
  const { handleOpenModal } = useModal();

  // const checkSubdomainExists = async (subdomain) => {
  //   try {
  //     const response = await postRequestUnAuthenticated(`/username/exist`,{username:subdomain});
  //     if (response.success) {
  //       dispatch(setGalleryData({ username: subdomain }));
  //       setSubdomainExists(true);
  //     } else {
  //       setSubdomainExists(false);
  //     }
  //   } catch (error) {
  //     console.error('Error checking subdomain:', error);
  //     setSubdomainExists(false);
  //   }
  // };

  useEffect(() => {
    const url = new URL(window.location.href);
    const subdomain = extractSubdomain(url);
    if (subdomain) {
      // checkSubdomainExists(subdomain);
      setHasSubdomain(true);
      dispatch(setGalleryData({ username: subdomain }));
    }
  }, [dispatch]);

  const isOnline = useSelector((state) => state.network.isOnline);

  useEffect(() => {
    if (checkTokenExpiry()) {
      handleOpenModal("modalTokenExpiryOpen");
    }
    if (!isOnline) {
      alert("Interent connection is lost.");
    }
  }, []);

  const [modalOnboardOpen, setModalOnboardOpen] = useState(false);

  const handleOnboardOpenModal = () => {
    setModalOnboardOpen(true);
  };

  const MainLayout = ({ onLoginClick }) => (
    <div className="flex flex-col min-h-screen bg-primaryBackground w-full">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Chips.Social</title>
        <link rel="icon" href={Logo} />
      </Helmet>
      <Navbar onLoginClick={onLoginClick} />
      <div className="flex flex-1 ">
        <Sidebar />
        <div className="flex-1 mt-1 pl-5 xs:pl-16 sm:pl-[72px] w-full-minus-64">
          <Outlet />
        </div>
      </div>
      <div className="w-full">
        <Footer />
      </div>
    </div>
  );

  return (
    <Router>
      <GoogleOAuthProvider clientId={clientId}>
        <ScrollToTop />
        {!isOnline ? (
          <Internetconnection />
        ) : (
          <Routes>
            {hasSubdomain ? (
              // subdomainExists?(
              <>
                <Route path="/" element={<MainLayout />}>
                  <Route path="/" element={<Gallery />} />
                  <Route path="/saved/:userId/:tab" element={<Saved />} />
                  <Route path="/curation/:curId" element={<CurationView />} />
                  <Route
                    path="/categories/:category"
                    element={<CurationsCategory />}
                  />
                  <Route
                    path="/categories/:category/curation/:curId"
                    element={<CurationView />}
                  />
                  <Route path="/bugs" element={<QueryPage />} />
                  <Route
                    path="/admin/edit/chips/curations/profile/66c5c1e385"
                    element={<AdminHome />}
                  />
                  <Route
                    path="/user/:username/chip/:chipId"
                    element={<ChipState />}
                  />
                  <Route path="/profile/:username" element={<Profile />} />
                  <Route
                    path="/subscriptions/:username"
                    element={<SubscriptionsView />}
                  />
                  <Route path="/curation/:curId" element={<CurationView />} />
                  <Route
                    path="/profile/:username/curation/:curId"
                    element={<ProfileChipsView />}
                  />
                  <Route path="/404" element={<Domain404 />} />
                  <Route path="*" element={<Page404 />} />
                </Route>
                <Route
                  path="/reset-password/:token"
                  element={<ResetPassword />}
                />
              </>
            ) : (
              // ):(
              //   <Route path="/" element={<Domain404/>} />
              // )
              <Route path="/" element={<MainLayout />}>
                <Route path="/saved/:userId/:tab" element={<Saved />} />
                <Route
                  path="/user/:username/chip/:chipId"
                  element={<ChipState />}
                />
                <Route
                  path="/categories/:category"
                  element={<CurationsCategory />}
                />
                <Route
                  path="/categories/:category/curation/:curId"
                  element={<CurationView />}
                />
                <Route path="/profile/:username" element={<Profile />} />
                <Route
                  path="/profile/:username/curation/:curId"
                  element={<ProfileChipsView />}
                />
                <Route path="/curation/:curId" element={<CurationView />} />
                <Route
                  path="/subscriptions/:username"
                  element={<SubscriptionsView />}
                />
                <Route
                  path="/"
                  element={<Home onboardClick={handleOnboardOpenModal} />}
                />
                <Route
                  path="/manage/mysubscriptions"
                  element={<ManageSubscriptions />}
                />
                <Route path="/bugs" element={<QueryPage />} />
                <Route path="/profile/:username/404" element={<Domain404 />} />
                <Route path="*" element={<Page404 />} />
              </Route>
            )}
            <Route path="/reset-password/:token" element={<ResetPassword />} />
          </Routes>
        )}
        <LoginModal onboardClick={handleOnboardOpenModal} />
        <CurationModal />
        <SaveChip />
        <ChipsModal />
        <OnboardingModal />
        <UnsplashModal />
        <Unsplash />
        <PrivacyPolicy />
        <TermsofService />
        <DeleteChipModal />
        <DeleteCurationModal />
        <ShareModal />
        <EditChipModal />
        <ChipShareModal />
        <ProfileShareModal />
        <LogoutModal />
        <TokenExpiryModal />
        <UnsubscriptionModal />
        <DocumentModal />
        <CommentChipModal />
        <CreateCategoryModal />
        <DeleteCategoryModal />
        <PushtoCategoryModal />
        <PushtoCurationModal />
        <CategoryReorderModal />
      </GoogleOAuthProvider>
    </Router>
  );
};

export default App;
