import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {setCurationEngagement } from './../../redux/slices/curationEngagementSlice';
import { useDispatch } from 'react-redux';

const CurationMansonry = ({curation,owner,gallery}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleChipsPage = () => {
    dispatch(setCurationEngagement(curation._id));
    if(gallery){
      navigate(`/curation/${curation._id}`, { state: { owner } });
    }
    else{
      const username = location.pathname.split('/')[2]; 
      navigate(`/profile/${username}/curation/${curation._id}`, { state: { owner } });
    }
  };

  return (
    <div className="flex flex-col items-start flex-shrink-0  cursor-pointer max-h-56 overflow-hidden rounded-lg w-full">
    <img
      className="rounded-xl object-cover w-full h-44"
      src={curation.image}
      alt={curation.name}
      onClick={handleChipsPage}
    />
    <p
      className="text-white  text-sm sm:text-base mt-0.5 w-full font-inter font-normal text-left overflow-hidden"
      style={{
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        lineHeight: '1.5rem',
       
      }}
    >
      {curation.name}
    </p>
  </div>
  );
};

export default CurationMansonry;
